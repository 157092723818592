<template lang="pug">
.amount(:class="classes")
  span.currency__symbol.padding-right-quarter(v-if="parts.symbolPosition === 'start'") {{ parts.symbol }}
  span.currency__sign(v-if="isNegative") -
  span.currency__integer {{ parts.integer }}
  span.currency__decimal(v-if="!isInteger && parts.decimal") {{ parts.decimal }}
  span.currency__symbol.padding-left-quarter(v-if="parts.symbolPosition === 'end'") {{ parts.symbol }}
</template>

<script lang="ts" setup>
export interface Props {
  value: number
  locale: string
  currency: string
  isInteger?: boolean
  decimals?: number
  colored?: boolean
}
const props = withDefaults(defineProps<Props>(), {
  isInteger: false,
  decimals: 2,
  colored: false
})

const isNegative = computed(() => props.value < 0)
const parts = computed(() => useCurrencyFormat(props.value, props.currency, props.locale, props.decimals))
const classes = computed(() => ({
  'currency--negative': props.colored && isNegative.value,
  'currency--positive': props.colored && !isNegative.value
}))

/**
 * Format a number as a currency string
 *
 * @param {number} amount - The numeric value to format
 * @param {string} currency - The ISO 4217 currency code (e.g., 'USD', 'EUR')
 * @param {string} locale - The locale identifier (e.g., 'es-ES')
 * @param {number} decimals - The number of decimal places to display
 */
function useCurrencyFormat(
  amount: number,
  currency: string,
  locale: string,
  decimals: number
) {
  const formatter = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals
  });

  const formattedParts = formatter.formatToParts(amount);
  const parts = {
    symbol: '',
    integer: '',
    decimal: '',
    symbolPosition: 'start'
  };

  formattedParts.forEach(part => {
    // Handle different parts of the formatted amount.
    if (part.type === 'currency') {
      parts.symbol = part.value;
      parts.symbolPosition = formattedParts.indexOf(part) <= 1 ? 'start' : 'end';
    } else if (part.type === 'integer' || part.type === 'group') {
      parts.integer += part.value;
    } else if (part.type === 'fraction' || part.type === 'decimal') {
      parts.decimal += part.value;
    }
  });

  // Spanish locale has a specific behavior for the thousands separator
  // If the value is between 1000 and 9999, the thousands separator isn't displayed
  if ((locale === 'es-ES' || locale === 'es') && Math.abs(amount) >= 1000 && Math.abs(amount) < 10000 && !parts.integer.includes('.')) {
    parts.integer = parts.integer.slice(0, 1) + '.' + parts.integer.slice(1);
  }

  return parts;
}

</script>

<style scoped lang="scss">
.amount {
  display: inline-block;
}

.currency__symbol,
.currency__decimal {
  font-size: 0.8em;
}

.currency--negative {
  color: color(danger);
}

.currency--positive {
  color: color(success);
}
</style>
